@font-face {
  font-family: plumppixel;
  src: url("./assets/fonts/plumppixel.ttf") format("truetype");
}

@font-face {
  font-family: GeeBee1978;
  src: url("./assets/fonts/GeeBee1978.ttf") format("truetype");
}
@font-face {
  font-family: QuizShow1976;
  src: url("./assets/fonts/QuizShow1976.ttf") format("truetype");
}
@font-face {
  font-family: DkCoolCrayon;
  src: url("./assets/fonts/DkCoolCrayon.ttf") format("truetype");
}
@font-face {
  font-family: CrayonKids1;
  src: url("./assets/fonts/CrayonKids1.ttf") format("truetype");
}
@font-face {
  font-family: HoonWhitecat;
  src: url("./assets/fonts/1HoonWhitecat.ttf") format("truetype");
}
@font-face {
  font-family: Monda;
  src: url("./assets/fonts/Monda-Regular.ttf") format("truetype");
}

$DARK: #333539;
$DARKER: #14171b;
$LIGHT: #c2c6c4;
$LIGHTER: #999;
$PRIMARY_DARK: rgba(97, 129, 116, 0.5);
body {
  width: 100%;
  min-height: 100vh;
  background-color: $DARKER;
  font-family: Monda;
  color: $LIGHT;
}
* {
  margin: 0;
  box-sizing: border-box;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;

  &.col {
    flex-direction: column;
  }

  &.w {
    width: 100%;
    position: relative;
  }
}
.halflex {
  @extend .flex;
  width: calc(5rem + 53vmin);
  position: relative;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.halfrow {
  flex: 1;
  @extend .flex, .col;
}

.App {
  text-align: center;
  min-height: 100vh;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  font-size: 1rem;
}

.app {
  max-width: 1000px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  &:before {
    content: url("/assets/img/StarBg.png");
    background: url("/assets/img/StarBg.png") repeat bottom left;
    position: absolute; /*or absolute*/
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.5;
  }
}

.card {
  width: 100%;
  height: 90vh;
  border-radius: 20px;
  background-color: $DARKER;
  margin: auto;
  box-shadow: 5px 5px 10px 2px #000;
}

.title {
  @extend .flex;
  text-align: center;
  font-weight: 400;
  font-size: 28px;
  margin-right: 64px;
  img {
    width: 64px;
  }
  h1 {
    position: relative;
    font-weight: 500;
    &:after {
      content: "";
      position: absolute;
      left: 32px;
      right: 32px;
      bottom: 0;
      height: 2px;
      background-color: $PRIMARY_DARK;
    }
  }
}

.text-primary {
  color: $PRIMARY_DARK;
}
.text-sec {
  color: $LIGHTER;
  font-family: HoonWhitecat;
  font-weight: 700;
}
.abs-center {
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
}
.mh-3 {
  margin: 0 0.5rem;
}
.mt-3 {
  margin-top: 0.375rem;
}
.mt-5 {
  margin-top: 1rem;
}
.mh-5 {
  margin: 0 1rem;
}

.p-3 {
  padding: 0.375rem;
}

.item {
  @extend .flex;
  padding: 0 1rem;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #d9d9d9;
  &.small {
    width: 11px;
    height: 11px;
  }
}
.half-divider {
  width: calc(5rem + 53vmin);
  height: 1;
  border: 1px white solid;
}
.vert-divider {
  width: 2px;
  height: calc(0.2rem + 4vmin);
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  top: 0;
  position: absolute;
  background: #d9d9d9;
}
.icon {
  width: calc(0.5rem + 4vmin);
  height: calc(0.5rem + 4vmin);
  transition: 0.3s all;
  filter: invert(1);
  /* or to blue */
  &.Flower {
    filter: invert(0.8) sepia(0.2) saturate(1) hue-rotate(175deg);
  }
  &.Skills {
    filter: invert(0.2) sepia(0.3) saturate(2) hue-rotate(75deg);
  }
  &.Briefcase {
    filter: invert(0.1) sepia(0.3) saturate(2) hue-rotate(5deg);
  }
  &.Fire {
    filter: invert(0.5) sepia(0.5) saturate(3) hue-rotate(5deg);
  }
  &:hover {
    &.Flower {
      filter: invert(0.4) sepia(0.5) saturate(2) hue-rotate(175deg);
    }
    &.Skills {
      filter: invert(0.2) sepia(0.3) saturate(4) hue-rotate(85deg);
    }
    &.Briefcase {
      filter: invert(0.1) sepia(0.5) saturate(4) hue-rotate(5deg);
    }
    &.Fire {
      filter: invert(0.5) sepia(1) saturate(5) hue-rotate(5deg);
    }
  }
  &.letter,
  &.moon {
    filter: none;
  }
}

.text-header {
  color: #e2e6e4;
  font-size: 1.5rem;
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
  position: relative;
  &.big {
    text-align: center;
    color: white;
    font-size: calc(1rem + 5vmin);

    .icon {
      left: -8%;
      top: 20%;
      position: absolute;
    }
  }
  &.subbig {
    flex: 1;
    padding: 0 calc(0.2rem + 3vmin);
    text-align: center;
    color: white;
    font-size: calc(0.2rem + 3vmin);
  }
}
.text-subheader {
  color: #dadada;
  font-size: 1.2rem;
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
  text-align: "left";
  width: 100%;
  padding: 0.2rem 0;
}
.text-item {
  color: #cccccc;
  font-size: 0.8rem;
  font-weight: 400;
  word-wrap: break-word;
  text-align: left;
  width: 100%;
  padding: 4px;
  white-space: nowrap;
  &.highlighted {
    color: #cdad7d;
  }
}

.Top {
  width: 100%;
  @extend .flex;
  flex-wrap: wrap;
  position: relative;
  height: 270;
}
.mid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  left: -100px;
  top: 0;

  .first-sec {
    flex: 1 1 700px;
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
  }
  .avatar {
    @extend .flex, .col;
    position: relative;
    height: 200px;
    width: 200px;

    img {
      width: 150%;
      margin: 0 2rem;
      position: absolute;
      top: -120px;
    }
  }

  .work {
    @extend .flex, .col;
    flex: 1 1 300px;
    position: relative;
    height: 550px;
    width: 300px;
  }
  .interest {
    @extend .flex, .col;
    width: 200px;
    height: 0;
    flex: 1 1 200px;
    position: relative;

    .interest-float {
      @extend .flex;
      position: absolute;
      width: 200px;
      top: -200px;
      left: 200px;
      right: 0;
      margin-right: auto;
      margin-left: auto;
    }

    .linedot {
      min-width: 16px;
      height: 150px;
      position: relative;
    }
  }
}
.bottom {
  @extend .flex, .w;
  height: 200px;
}
.text-val-item {
  @extend .flex;
  padding: 0.5rem;
  width: calc(2rem + 23vmin);
}
.text-label {
  text-align: left;
  color: #b5bab8;
  font-size: calc(0.2rem + 1vmin);
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
  flex: 1;
}

.text-value {
  text-align: right;
  color: #929f9a;
  font-size: calc(0.2rem + 1vmin);
  font-weight: 700;
  word-wrap: break-word;
  white-space: nowrap;
  flex: 1;
}
.text-parag {
  color: #929f9a;
  font-size: calc(0.3rem + 1vmin);
  font-weight: 300;
}
@media screen and (max-width: 640px) {
  .halflex {
    flex-direction: column;
    padding: 1.5rem 0;
  }
  .text-val-item {
    width: calc(8rem + 35vmin);
    .text-label {
      font-size: calc(0.5rem + 2vmin);
    }
    .text-value {
      font-size: calc(0.5rem + 2vmin);
    }
  }
}

@media screen and (max-width: 1000px) {
  .mid {
    left: 0;
    flex-direction: column;
    .first-sec {
      width: 100%;
      flex: 1;
      flex-direction: column-reverse;

      .avatar {
        margin: 100px 0;
        img {
          top: 0;
          position: relative;
        }
      }
    }
    .work {
      width: 100%;
      flex: 1 1 650px;
      margin-top: 5rem;
    }

    .interest {
      width: 100%;
      .interest-float {
        top: 0;
        left: 0;
      }
    }
  }
  .bottom {
    width: 100%;
    .text-parag {
      width: 60%;
    }
  }
}

.portfolio {
  @extend .flex, .col;
  width: 100%;
  max-width: 1000px;
  position: relative;
  margin-top: 2rem;
  .polaroid-container {
    padding: 3rem 0;
    flex: 1;
    width: 100%;
    @extend .flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.politem {
  max-width: 300px;
  flex: 1 1 300px;
  display: inline-block;
  filter: grayscale(100%);
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.3);
  &:hover {
    filter: none;
    transform: scale(1, 1) rotate(0deg) !important;
    transition: all 0.35s;

    .polaroid {
      &.meltdown {
        img {
          content: url(/assets/img/portf/meltdown.gif);
        }
      }

      &.biki {
        transform: scale(2, 2) rotate(0deg) !important;
        img {
          content: url(/assets/img/portf/biki.gif);
        }
      }
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        transform: rotate(0deg);
        height: 90%;
        width: 90%;
        bottom: 0%;
        right: 5%;
        box-shadow: 0 1rem 2rem rgba(255, 255, 255, 0.3);
        transition: all 0.35s;
      }
    }
  }
  .polaroid {
    background: #fff;
    padding: 0.3rem;
    box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: auto;
      max-height: 200px;
      object-fit: cover;
      border: 1px solid $DARK;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      transition: all 0.35s;
    }
    &.peepo {
      img {
        content: url(/assets/img/portf/peepocraft.jpg);
      }
    }
    &.meltdown {
      img {
        content: url(/assets/img/portf/meltdown.jpg);
      }
    }
    &.nyxethosq {
      img {
        content: url(/assets/img/portf/NyxethosQ.jpg);
      }
    }
    &.biki {
      img {
        content: url(/assets/img/portf/biki.jpg);
      }
    }
  }
  transition: all 0.35s;

  &:nth-child(1n) {
    transform: scale(0.8, 0.8) rotate(-5deg);
  }

  &:nth-child(2n) {
    transform: scale(0.8, 0.8) rotate(2deg);
  }

  &:nth-child(3n) {
    transform: scale(0.8, 0.8) rotate(-6deg);
  }

  &:nth-child(4n) {
    transform: scale(0.8, 0.8) rotate(3deg);
  }
}
.caption {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.5rem;
  padding: 1rem;
  font-family: DkCoolCrayon;
  color: $DARK;
}

.UncerConstruction {
  @extend .flex, .col;
  min-height: 600px;
  img {
    width: 300px;
  }
}

.cov {
  text-align: left;
  h3 {
    padding: 1rem 0;
  }
  p {
    margin-top: 1rem;
  }
}
.bold {
  font-weight: 700;
  font-family: DkCoolCrayon;
}
